import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  static get targets() {
    return [
      'form',
      'upload',
      'alexEvent',
      'speakerVideo',
      'slidesVideo',
      'disableWhenUploading',
      'hideWhenUploading',
      'hideWhenUploadComplete',
      'file',
    ];
  }

  startUpload() {
    if (!this.validate()) {
      return;
    }

    const formData = this.formDataObject;
    const uploadParams = {
      filesToUpload: this.filesToUpload,
      presentationId: formData['video_upload[presentation_id]'],
      alexEventId: formData['video_upload[alex_event_id]'],
      noAlexEvent: formData['video_upload[no_alex_event]'],
      uploadToSubmittedEvent: formData['video_upload[upload_to_submitted_event]'],
      render4kSpeakerVideo: formData['video_upload[render_4k_speaker_video]'],
      videoInSlides: formData['video_upload[videos_in_slides]'],
      palpatineTest: formData['video_upload[palpatine_test]'],
      title: formData['video_upload[title]'],
      recordedAt: formData['video_upload[recorded_at]'],
      folderId: formData['video_upload[folder_id]'],
    };

    this.uploadController.startUpload(uploadParams);

    this.uploadTarget.hidden = false;
    this.updateUploadingStatus('uploading');
  }

  stopUpload() {
    this.uploadController.stopUpload();
  }

  validate() {
    let valid = true;

    if (!this.formController.validate()) {
      valid = false;
    }

    for (const fileController of this.filesControllers) {
      if (!fileController.validate()) {
        valid = false;
      }
    }

    return valid;
  }

  updateUploadingStatus(status) {
    for (const target of this.disableWhenUploadingTargets) {
      target.disabled = status === 'uploading' || status === 'complete';
    }

    for (const target of this.hideWhenUploadingTargets) {
      target.hidden = status === 'uploading' || status === 'complete';
    }

    for (const target of this.hideWhenUploadCompleteTargets) {
      target.hidden = status === 'complete';
    }

    for (const fileController of this.filesControllers) {
      fileController.updateUploadingStatus(status);
    }

    if (status === 'uploading') {
      this.modalController.preventClose();
    } else {
      this.modalController.allowClose();
    }
  }

  uploadComplete() {
    this.updateUploadingStatus('complete');
  }

  uploadError() {
    this.updateUploadingStatus('error');
  }

  get filesToUpload() {
    const speakerVideoFile = this.speakerVideoFileController.fileToUpload({ name: 'video' });
    const slidesVideoFile = this.slidesVideoFileController.fileToUpload({ name: 'slides' });

    return [speakerVideoFile, slidesVideoFile].filter((file) => file !== null);
  }

  get formData() {
    return new FormData(this.formTarget);
  }

  get formDataObject() {
    return Object.fromEntries(this.formData.entries());
  }

  get formController() {
    return this.findControllerByName('fuse--form', { useQuerySelector: false, element: this.formTarget });
  }

  get uploadController() {
    return this.findControllerOnElement(this.uploadTarget);
  }

  get filesControllers() {
    return this.fileTargets.map((fileTarget) => this.findControllerOnElement(fileTarget));
  }

  get speakerVideoFileController() {
    return this.findControllerOnElement(this.speakerVideoTarget);
  }

  get slidesVideoFileController() {
    return this.findControllerOnElement(this.slidesVideoTarget);
  }

  get modalController() {
    return this.findControllerByName('fuse--modal-content-v2', { useQuerySelector: false });
  }
}
