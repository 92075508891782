import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  static get targets() {
    return ['priceCents', 'priceCurrency'];
  }

  privacyChanged(event) {
    const detail = { privacy: event.target.value };
    this.dispatch('privacyChanged', { detail });
  }

  priceChanged() {
    const detail = {
      priceCents: parseFloat(this.priceCentsTarget.value) * 100,
      priceCurrency: this.priceCurrencyTarget.value,
    };
    this.dispatch('priceChanged', { detail });
  }
}
