import ApplicationController from 'modules/application_controller';
import formatSize from 'plugins/number/format_size';
import formatTimeString from 'plugins/number/format_time_string';
import getFilesFromDataTransfer from 'plugins/utilities/get_files_from_data_transfer';

export default class extends ApplicationController {
  static get targets() {
    return ['fileLabel', 'fileMetadata', 'selectFile', 'removeFile'];
  }

  initialize() {
    this.props = {
      file: null,
      uploading: false,

      videoDuration: null,
    };
  }

  connect() {
    this._updateDisabledButtons();
  }

  ignoreDragDrop(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  startDrag(event) {
    event.dataTransfer.dropEffect = 'copy';
    this.element.classList.add('dragDropActive');
  }

  stopDrag() {
    this.element.classList.remove('dragDropActive');
  }

  drop(event) {
    getFilesFromDataTransfer(event.dataTransfer).then((files) => this._setFiles(files));
  }

  selectFileFromDisk() {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.mp4,video/mp4';
    input.onchange = () => this._setFiles(Array.from(input.files));
    input.click();
  }

  removeFile() {
    this.props.file = null;
    this.props.videoDuration = null;

    this._updateDisabledButtons();
    this._updateFileFields();
  }

  validate() {
    return true;
  }

  fileToUpload(meta) {
    if (!this.props.file) {
      return null;
    }

    return {
      name: this.props.file.name,
      type: this.props.file.type,
      data: this.props.file,
      meta,
    };
  }

  updateUploadingStatus(status) {
    this.props.uploading = status === 'uploading' || status === 'complete';
    this._updateDisabledButtons();
  }

  _setFiles(files) {
    if (this.props.uploading) {
      return;
    }

    const file = files[0];
    if (!file.name.toLowerCase().endsWith('.mp4') && file.type !== 'video/mp4') {
      this.removeFile();
      return;
    }

    this.props.file = file;

    this._updateDisabledButtons();
    this._updateFileFields();

    this._loadFileMetadata();
  }

  _updateDisabledButtons() {
    if (this.props.uploading) {
      this.selectFileTarget.disabled = true;
      this.removeFileTarget.disabled = true;
    } else {
      this.selectFileTarget.disabled = false;
      this.removeFileTarget.disabled = !this.props.file;
    }
  }

  _updateFileFields() {
    if (this.props.file) {
      this.fileLabelTarget.textContent = this.props.file.name;
      this.fileMetadataTarget.textContent = this._formatFileMetadataText();
      this.fileMetadataTarget.hidden = false;
    } else {
      this.fileLabelTarget.textContent = this.defaultFileLabel;
      this.fileMetadataTarget.hidden = true;
    }
  }

  _formatFileMetadataText() {
    if (!this.props.file) {
      return '';
    }

    const parts = [];

    if (this.props.videoDuration) {
      parts.push(formatTimeString(this.props.videoDuration, { useColons: true }));
    }

    parts.push(formatSize(this.props.file.size));

    return parts.join(' · ');
  }

  _loadFileMetadata() {
    const video = document.createElement('video');
    video.preload = 'metadata';
    video.onloadedmetadata = () => {
      window.URL.revokeObjectURL(video.src);

      if (this.props.file) {
        this.props.videoDuration = video.duration;
        this._updateFileFields();
      }
    };

    video.src = URL.createObjectURL(this.props.file);
  }

  get defaultFileLabel() {
    return this.element.dataset.defaultFileLabel;
  }
}
