import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  open(event) {
    event.preventDefault();

    const target = event.currentTarget;
    const url = target.getAttribute('href');
    const name = `SlidesLive ${target.getAttribute('title')} Share`;

    const width = 575;
    const height = 400;

    this.openPopup(width, height, url, name);
  }

  openPopup(width, height, url, name) {
    const left = (window.outerWidth - width) / 2;
    const top = (window.outerHeight - height) / 2;

    let opts = 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes';
    opts += `,width=${width}`;
    opts += `,height=${height}`;
    opts += `,top=${top}`;
    opts += `,left=${left}`;

    window.open(url, name, opts);
  }
}
