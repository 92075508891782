import ApplicationController from 'modules/application_controller';
import pushDataLayer from 'plugins/utilities/push_data_layer';

export default class extends ApplicationController {
  dispatchTypeChangedEvent(event) {
    event.stopPropagation();

    const searchType = this.searchTypeFromTabChangedEvent(event);
    this.dispatch('typeChanged', {
      target: document,
      prefix: 'search',
      detail: { type: searchType },
    });
  }

  sendGoogleAnalyticSearchEvent(event) {
    const searchEvent = this.element.dataset.searchGaEvent || 'sl_search';
    const searchQuery = this.element.dataset.searchQuery;
    const searchType = this.searchTypeFromTabChangedEvent(event);

    pushDataLayer({ event: searchEvent, search_type: searchType, search_query: searchQuery });
  }

  searchTypeFromTabChangedEvent(event) {
    if (!event) return this.element.dataset.initialSearchType;

    return event.detail.relatedTarget.dataset.tabName;
  }
}
