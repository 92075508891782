import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  initialize() {
    this.props = {
      reloadTimeout: null,
    };
  }

  connect() {
    this.scheduleReload();
  }

  disconnect() {
    if (this.reloadTimeout) {
      clearTimeout(this.reloadTimeout);
    }
  }

  scheduleReload() {
    this.reloadTimeout = setTimeout(() => {
      this.dispatch('reload');
    }, this.checkInterval);
  }

  get reloadTimeout() {
    return this.props.reloadTimeout;
  }

  set reloadTimeout(value) {
    this.props.reloadTimeout = value;
  }

  get checkInterval() {
    return 10000;
  }
}
