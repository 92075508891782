import PlayerBaseController from 'library/player_base_component/player_base_component';
import urlParam from 'plugins/utilities/url_param';

export default class extends PlayerBaseController {
  initPlayer() {
    if (!super.initPlayer()) {
      return;
    }

    this.player.on('play', () => {
      if (this.player.firstPlay) {
        this.dispatch('firstPlay');
      }
    });

    this.player.on('load', (duration, slideCount) => {
      this.dispatch('load', { detail: { duration, slideCount } });
    });

    this.player.on('timeChanged', (time, duration) => {
      this.dispatch('timeChanged', { detail: { time, duration } });
    });

    this.player.on('currentSlideChanged', (slideIndex, slideCount) => {
      this.dispatch('currentSlideChanged', { detail: { slideIndex, slideCount } });
    });

    this.player.on('togglePlaylist', (name, added) => {
      this.dispatch('togglePlaylist', { detail: { name, added } });
    });

    this.player.on('toggleBookmark', (added) => {
      this.dispatch('toggleBookmark', { detail: { added } });
    });
  }

  get autoPlay() {
    return !!urlParam('query');
  }

  get playerOptions() {
    const startTime = urlParam('startTime');
    const startSlide = urlParam('startSlide');
    const options = {
      autoPlay: this.autoPlay,
    };

    if (startTime) {
      options.startTime = parseInt(startTime, 10);
    } else if (startSlide) {
      options.startSlide = parseInt(startSlide, 10);
    }

    return options;
  }
}
