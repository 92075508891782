import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  static get targets() {
    return ['hourlyRateCurrency'];
  }

  updateHourlyRateCurrency(event) {
    const currency = event.target.value;

    for (const hourlyRateCurrencyTarget of this.hourlyRateCurrencyTargets) {
      hourlyRateCurrencyTarget.value = currency;
    }
  }
}
