import ApplicationController from 'modules/application_controller';
import checkDelayedResult from 'plugins/utilities/check_delayed_result';

export default class extends ApplicationController {
  static get targets() {
    return ['submit', 'spinner', 'result'];
  }

  restart() {
    this.submitTarget.disabled = true;
    this.spinnerTarget.hidden = false;
    this.resultTarget.textContent = 'Sending.';

    fetch(this.url, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
      },
    })
      .then((response) => {
        this.resultTarget.textContent = 'Scheduled.';

        response.json().then((result) => {
          if (!result.delayed_result_id) {
            this.resultTarget.textContent = 'No result ID.';
            return;
          }

          checkDelayedResult(
            result.delayed_result_id,
            (res) => {
              this.resultTarget.textContent = res.success ? res.stdout : res.errors;
            },
            500,
          );
        });
      })
      .catch(() => {
        this.resultTarget.textContent = 'Error.';
      })
      .finally(() => {
        this.submitTarget.disabled = false;
        this.spinnerTarget.hidden = true;
      });
  }

  get url() {
    return this.submitTarget.dataset.url;
  }
}
