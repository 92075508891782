import ApplicationController from 'modules/application_controller';
import pushDataLayer from 'plugins/utilities/push_data_layer';

export default class extends ApplicationController {
  initialize() {
    this.sendGoogleAnalyticEvent();
  }

  sendGoogleAnalyticEvent() {
    const event = this.element.dataset.sendGaEvent;
    if (!event) return;

    pushDataLayer({ event });
  }
}
