import ApplicationController from 'modules/application_controller';

import ResizeObserver from 'resize-observer-polyfill';

export default class extends ApplicationController {
  connect() {
    this.resizeObserver = new ResizeObserver(() => {
      this.sendSetSize();
    });

    this.resizeObserver.observe(this.element);

    this.embedController.sendUpdateSizeRequest();
    this.sendSetSize();
  }

  disconnect() {
    this.resizeObserver.disconnect();
    this.resizeObserver = null;
  }

  sendSetSize() {
    this.embedController.sendSetSize({ height: this.element.offsetHeight });
  }

  get embedController() {
    return this.findParentControllerByName('library--embed--body', { hideWarning: true });
  }
}
