import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  static get targets() {
    return ['thumbnail'];
  }

  updatePublished(published) {
    this.thumbnailController.setPublished(published);
  }

  updatePrivacy({ detail: { privacy } }) {
    this.thumbnailController.setPrivacy(privacy);
  }

  updatePrice({ detail: { priceCents, priceCurrency } }) {
    this.thumbnailController.setPrice(priceCents, priceCurrency);
  }

  publishedChanged({ target }) {
    this.updatePublished(target.checked);
  }

  get thumbnailController() {
    return this.application.getControllerForElementAndIdentifier(
      this.thumbnailTarget,
      'library--presentation-edit--thumbnail',
    );
  }
}
