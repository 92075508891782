import ApplicationController from 'modules/application_controller';
import createCookie from 'plugins/cookies/create';
import readCookie from 'plugins/cookies/read';
import remove from 'plugins/element/remove';

export default class extends ApplicationController {
  static get targets() {
    return ['passwordField', 'passwordInput'];
  }

  initialize() {
    this.props = {
      isHidden: false,
      pausePlayer: true,
      timeout: null,
    };
  }

  connect() {
    this.isHidden = readCookie(this.cookieName) === 'true';

    if (this.isHidden) {
      this.remove();
    }
  }

  disconnect() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  onHide() {
    this.dispatch('resumePlayer');
    this.timeout = setTimeout(this.remove.bind(this), 300);
    createCookie(this.cookieName, 'true', 30 * 24 * 60000);
  }

  startTimeout() {
    if (this.isHidden) {
      return;
    }

    this.timeout = setTimeout(this.show.bind(this), 2 * 60000);
  }

  requirePassword() {
    this.passwordInputTarget.disabled = false;
    this.passwordFieldTarget.hidden = false;
    this.passwordInputTarget.focus();
  }

  show() {
    this.timeout = null;
    this.dispatch('pausePlayer');
    this.dispatchOnWindow('open', { prefix: 'modal', detail: { name: this.element.dataset.modalName } });
  }

  hide() {
    this.dispatchOnWindow('close', { prefix: 'modal', detail: { name: this.element.dataset.modalName } });
  }

  remove() {
    this.timeout = null;
    remove(this.element);
  }

  get isHidden() {
    return this.props.isHidden;
  }

  set isHidden(isHidden) {
    this.props.isHidden = isHidden;
  }

  get pausePlayer() {
    return this.props.pausePlayer;
  }

  set pausePlayer(pausePlayer) {
    this.props.pausePlayer = pausePlayer;
  }

  get timeout() {
    return this.props.timeout;
  }

  set timeout(value) {
    this.props.timeout = value;
  }

  get cookieName() {
    return 'sign-up-interruption-modal-hidden';
  }
}
