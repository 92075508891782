import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  static get targets() {
    return ['name'];
  }

  setName(event) {
    this.nameTarget.value = event.detail.label;
  }
}
