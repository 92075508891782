import getData from 'plugins/data/get';
import setData from 'plugins/data/set';
import fire from 'plugins/utilities/fire';

function permanentPlayerKey() {
  return 'permanentPlayer';
}

function save({ presentationId, playerElement }) {
  setData(global, permanentPlayerKey(), { presentationId, playerElement });
}

function clear() {
  setData(global, permanentPlayerKey(), undefined);
}

function permanentPlayerData() {
  return getData(global, permanentPlayerKey());
}

function currentPresentationId() {
  return permanentPlayerData()?.presentationId;
}

function currentPlayerElement() {
  return permanentPlayerData()?.playerElement;
}

function exist() {
  return !!permanentPlayerData();
}

function open({ presentationId, playerElement, url, title }) {
  return fire(document, `${permanentPlayerKey()}:open`, {
    detail: {
      presentationId,
      playerElement,
      url,
      title,
    },
    bubbles: true,
    cancelable: true,
  });
}

function close() {
  return fire(document, `${permanentPlayerKey()}:close`, {
    bubbles: true,
    cancelable: true,
  });
}

export {
    clear, close, currentPlayerElement, currentPresentationId, exist,
    open, permanentPlayerData, permanentPlayerKey, save
};

