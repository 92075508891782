import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  static get targets() {
    return ['servers'];
  }

  initialize() {
    this.props = {
      serversSpinner: null,
    };

    this.serversSpinner = this.serversTarget.innerHTML;
  }

  reloadServers() {
    this.serversTarget.innerHTML = this.serversSpinner;
    this.serversTarget.reload();
  }

  get serversSpinner() {
    return this.props.serversSpinner;
  }

  set serversSpinner(value) {
    this.props.serversSpinner = value;
  }
}
