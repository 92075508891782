import ApplicationController from 'modules/application_controller';
import * as PermanentPlayer from 'modules/permanent_player';
import defer from 'plugins/utilities/defer';

export default class extends ApplicationController {
  static get targets() {
    return ['playerTemplate', 'playerWrapper', 'sharingSection', 'signUpInterruptionModal'];
  }

  initialize() {
    this.props = {
      url: null,
      wasPlaying: false,
    };

    const url = new URL(window.location);
    this.url = `${url.origin}${url.pathname}`;
  }

  connect() {
    if (this.isTurboPreview) {
      this.insertPlayer(null);

      return;
    }

    if (PermanentPlayer.currentPresentationId() === this.presentationId) {
      this.insertPlayer(PermanentPlayer.currentPlayerElement());

      defer(() => {
        PermanentPlayer.close();
      });
    } else {
      this.insertPlayer(this.playerTemplateTarget.innerHTML);
    }
  }

  disconnect() {
    if (!this.playerController || !this.playerController.player || this.playerController.player.firstPlay) return;
    if (this.playerController.player.videoServiceName.toLowerCase() === 'youtube') return;

    this.openInPermanentPlayer();
  }

  startSignUpInterruptionTimeout() {
    if (this.signUpInterruptionModalController) {
      this.signUpInterruptionModalController.startTimeout();
    }
  }

  setSharingStartTime(event) {
    if (!this.sharingSectionController) return;

    this.sharingSectionController.startTime = Math.round(event.detail.time / 1000.0);
  }

  setSharingShowStartSlide(event) {
    if (!this.sharingSectionController) return;

    this.sharingSectionController.showStartSlide = event.detail.slideCount > 0;
  }

  setSharingStartSlide(event) {
    if (!this.sharingSectionController) return;

    this.sharingSectionController.showStartSlide = event.detail.slideCount > 0;
    this.sharingSectionController.startSlide = event.detail.slideIndex;
  }

  openInPermanentPlayer() {
    const playerWrapper = this.playerWrapperTarget;
    const playerElement = playerWrapper.firstElementChild;
    const playerElementController = this.findControllerOnElement(playerElement);

    if (playerElementController) {
      playerElementController.isPermanent = true;
    }

    playerWrapper.removeChild(playerElement);

    PermanentPlayer.open({
      presentationId: this.presentationId,
      playerElement,
      url: this.url,
      title: this.title,
    });
  }

  insertPlayer(player) {
    this.playerWrapperTarget.innerHTML = '';

    if (!player) return;

    if (typeof player === 'string') {
      this.playerWrapperTarget.insertAdjacentHTML('afterbegin', player);
    } else {
      this.playerWrapperTarget.insertAdjacentElement('afterbegin', player);
    }
  }

  seekTo(event) {
    event.preventDefault();

    if (!this.playerController) return;

    const seconds = parseInt(event.target.dataset.seekTo, 10);

    this.playerController.seekTo(seconds);
  }

  seekSlide(event) {
    event.preventDefault();

    if (!this.playerController) return;

    const slide = parseInt(event.target.dataset.seekSlide, 10);

    this.playerController.seekSlide(slide);
  }

  pause() {
    if (!this.playerController) return;

    this.wasPlaying = this.playerController.player.isPlaying;
    this.playerController.pause();
  }

  resume() {
    if (!this.playerController || !this.wasPlaying) return;

    this.playerController.play();
    this.wasPlaying = false;
  }

  get playerController() {
    if (!this.playerWrapperTarget.firstElementChild) return null;

    return this.findControllerOnElement(this.playerWrapperTarget.firstElementChild);
  }

  get signUpInterruptionModalController() {
    if (!this.hasSignUpInterruptionModalTarget) return null;

    return this.findControllerByName('library--presentation-show--sign-up-interruption--modal', {
      useQuerySelector: false,
      element: this.signUpInterruptionModalTarget,
    });
  }

  get sharingSectionController() {
    if (!this.hasSharingSectionTarget) return null;

    return this.findControllerOnElement(this.sharingSectionTarget);
  }

  get presentationId() {
    return this.element.dataset.id;
  }

  get title() {
    return this.element.dataset.title;
  }

  get url() {
    return this.props.url;
  }

  set url(value) {
    this.props.url = value;
  }

  get wasPlaying() {
    return this.props.wasPlaying;
  }

  set wasPlaying(value) {
    this.props.wasPlaying = value;
  }
}
