import ApplicationController from 'modules/application_controller';
import * as PermanentPlayer from 'modules/permanent_player';

export default class extends ApplicationController {
  static get targets() {
    return ['playerWrapper', 'title', 'maximize'];
  }

  open({ detail: { presentationId, playerElement, title, url } }) {
    if (PermanentPlayer.exist()) {
      PermanentPlayer.close();
    }

    this.playerWrapperTarget.insertAdjacentElement('afterbegin', playerElement);

    this.maximizeTarget.href = url;
    this.titleTarget.href = url;
    this.titleTarget.textContent = title;

    this.element.hidden = false;

    PermanentPlayer.save({ presentationId, playerElement });
  }

  close() {
    this.element.hidden = true;

    if (this.playerController) {
      this.playerController.isPermanent = false;
    }

    this.playerWrapperTarget.innerHTML = '';
    this.maximizeTarget.href = '#';
    this.titleTarget.href = '#';
    this.titleTarget.textContent = '';

    PermanentPlayer.clear();
  }

  get playerController() {
    if (!this.playerWrapperTarget.firstElementChild) {
      return null;
    }

    return this.findControllerOnElement(this.playerWrapperTarget.firstElementChild);
  }
}
