import ApplicationController from 'modules/application_controller';
import Player from 'modules/player/player';
import addListener from 'plugins/utilities/add_listener';
import removeListener from 'plugins/utilities/remove_listener';

export default class extends ApplicationController {
  initialize() {
    this.player = null;

    this.initPlayer();
    this.embedController.sendUpdateSizeRequest();
  }

  connect() {
    this.messageListenerId = addListener(window, 'embed:message', this.handleMessage.bind(this));
  }

  disconnect() {
    removeListener(window, { id: this.messageListenerId });
  }

  initPlayer() {
    this.player = new Player('player');
  }

  handleMessage(event) {
    const {
      detail: { message },
    } = event;

    switch (message.method) {
      case 'play':
        this.player.play();
        break;

      case 'pause':
        this.player.pause();
        break;

      case 'toggle':
        this.player.toggle();
        break;

      case 'seekTo':
        this.player.seekTo(message.time);
        break;

      case 'seekToLivePosition':
        this.player.seekToLivePosition();
        break;

      case 'seekToSlide':
        this.player.seekToSlide(message.slide);
        break;

      case 'prevSlide':
        this.player.prevSlide();
        break;

      case 'nextSlide':
        this.player.nextSlide();
        break;

      case 'syncVideoToSlide':
        this.player.syncVideoToSlides();
        break;

      case 'syncSlideToVideo':
        this.player.syncSlidesToVideo();
        break;

      case 'setVolume':
        this.player.volume = message.volume;
        break;

      case 'setMuted':
        this.player.muted = message.muted;
        break;

      case 'setPlaybackRate':
        this.player.playbackRate = message.rate;
        break;

      case 'setZoomRatio':
        this.player.zoomRatio = message.ratio;
        break;

      // private api

      case 'setAvailableSize':
        if (this.player.setAvailableSize != null) {
          this.player.setAvailableSize(message.height);
        }
        break;

      case 'getSlideUrl':
        this.player.getSlideUrlFromEmbed(message.slide);
        break;

      default:
    }

    return true;
  }

  get embedController() {
    return this.findParentControllerByName('library--embed--body', { hideWarning: true });
  }
}
