import ApplicationController from 'modules/application_controller';
import ResumableUpload from 'modules/resumable_upload';
import formatSize from 'plugins/number/format_size';
import formatTimeString from 'plugins/number/format_time_string';
import checkDelayedResultWithPromise from 'plugins/utilities/check_delayed_result_with_promise';

export default class extends ApplicationController {
  static get targets() {
    return [
      'error',
      'errorList',
      'info',
      'infoList',
      'success',
      'successList',
      'uploadProgress',
      'progressBar',
      'speed',
      'eta',
      'uploadedBytes',
      'totalBytes',
      'hideWhenUploadComplete',
    ];
  }

  initialize() {
    this.upload = null;
  }

  disconnect() {
    if (this.upload) {
      this.stopUpload();
      this.upload = null;
    }
  }

  startUpload({
    filesToUpload,
    presentationId,
    alexEventId,
    noAlexEvent,
    uploadToSubmittedEvent,
    render4kSpeakerVideo,
    videoInSlides,
    palpatineTest,
    title,
    recordedAt,
    folderId,
  }) {
    this._dispatchInfo('Starting upload&hellip;');

    this.upload = new ResumableUpload({
      uploadInfoEndpoint: this.uploadInfoEndpoint,
      uploadInfoData: {
        upload: {
          presentation_id: presentationId,
          alex_event_id: alexEventId,
          no_alex_event: noAlexEvent,
          upload_to_submitted_event: uploadToSubmittedEvent,
          render_4k_speaker_video: render4kSpeakerVideo,
          videos_in_slides: videoInSlides,
          palpatine_test: palpatineTest,
          allow_new_presentation: !presentationId,
          new_presentation_attributes: {
            title,
            recorded_at: recordedAt,
            folder_id: folderId,
          },
        },
      },
    });

    this.upload.on('error', ({ error, errorMessage }) => {
      console.warn(error);

      this._dispatchError(errorMessage);
      this.upload = null;
    });

    this.upload.on('totalProgress', (progress) => {
      this._dispatchInfo('Uploading&hellip;');
      this._dispatchProgress(progress);
    });

    this.upload.on('totalComplete', (uploads) => {
      this._completeUpload({
        presentationId,
        alexEventId,
        noAlexEvent,
        uploadToSubmittedEvent,
        render4kSpeakerVideo,
        videoInSlides,
        palpatineTest,
        title,
        recordedAt,
        folderId,
        uploadService: this.upload.service,
        files: uploads,
      });
    });

    this.upload.initialize().then(() => {
      for (const file of filesToUpload) {
        try {
          this.upload.addFile(file.name, file.type, file.data, null, file.meta);
        } catch (error) {
          console.warn(error);

          this._dispatchError(`Error when adding file ${file.name} to upload.`);
          this.upload = null;

          return;
        }
      }

      this.upload.startUpload();
    });
  }

  stopUpload() {
    this.upload.abortUpload();
    this.upload = null;
  }

  _completeUpload({
    presentationId,
    alexEventId,
    noAlexEvent,
    uploadToSubmittedEvent,
    render4kSpeakerVideo,
    videoInSlides,
    palpatineTest,
    title,
    recordedAt,
    folderId,
    uploadService,
    files,
  }) {
    this._dispatchInfo('Completing upload&hellip;');

    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };

    const csrfTokenMetaTag = document.querySelector("meta[name='csrf-token']");
    if (csrfTokenMetaTag) {
      headers['X-CSRF-Token'] = csrfTokenMetaTag.content;
    } else {
      console.warn('CSRF token meta tag not found!');
    }

    const data = {
      upload: {
        presentation_id: presentationId,
        alex_event_id: alexEventId,
        no_alex_event: noAlexEvent,
        upload_to_submitted_event: uploadToSubmittedEvent,
        render_4k_speaker_video: render4kSpeakerVideo,
        videos_in_slides: videoInSlides,
        palpatine_test: palpatineTest,
        allow_new_presentation: !presentationId,
        new_presentation_attributes: {
          title,
          recorded_at: recordedAt,
          folder_id: folderId,
        },
        service: uploadService,
        files,
      },
    };
    const body = JSON.stringify(data);

    fetch(this.uploadCompleteEndpoint, {
      method: 'POST',
      cache: 'no-cache',
      credentials: 'include',
      headers,
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body,
    })
      .catch((error) => {
        console.warn(error);
        this._dispatchError('Error when completing upload: invalid request.');
        this.upload = null;
      })
      .then((response) => response.json())
      .catch((error) => {
        console.warn(error);
        this._dispatchError('Error when completing upload: invalid JSON.');
        this.upload = null;
      })
      .then((response) => {
        if (!response) {
          return { success: false, errors: ['Error when completing upload: no response.'] };
        }

        if (!response.success) {
          return { success: false, errors: response.errors };
        }

        return checkDelayedResultWithPromise(response.delayed_result_id);
      })
      .then((result) => {
        if (result.success) {
          this._dispatchComplete(result.presentation_id);
          this.upload = null;
        } else {
          this._dispatchError(result.errors.join('\n'));
          this.upload = null;
        }
      });
  }

  _dispatchInfo(message) {
    this.dispatch('info', { detail: { message } });
  }

  _dispatchError(message) {
    this.dispatch('error', { detail: { message } });
  }

  _dispatchProgress(progress) {
    this.dispatch('progress', { detail: { progress } });
  }

  _dispatchComplete(presentationId) {
    this.dispatch('complete', { detail: { presentationId } });
  }

  showInfo(event) {
    this._showInfo([event.detail.message]);
  }

  showError(event) {
    this._showError([event.detail.message]);
  }

  showComplete(event) {
    const { presentationId } = event.detail;

    this.uploadProgressTarget.hidden = true;
    this._showSuccess([
      'Upload complete. Your videos will be processed soon.',
      `In the meantime you can edit the presentation <a class="tw-text-white hover:tw-text-opacity-89 focus:tw-text-opacity-89 focus:tw-outline-none disabled:tw-cursor-not-allowed disabled:tw-text-opacity-50 tw-underline" href="https://slideslive.com/${presentationId}/-/edit">here</a>.`,
    ]);
  }

  updateProgress(event) {
    const progress = event.detail.progress;

    this.progressBarController.percentage = progress.totalProgress;
    this.speedTarget.textContent = formatSize(progress.totalSpeed, 'B/s');
    this.etaTarget.textContent = formatTimeString(progress.timeRemaining);
    this.uploadedBytesTarget.textContent = formatSize(progress.totalUploadedBytes);
    this.totalBytesTarget.textContent = formatSize(progress.totalBytes);

    this.uploadProgressTarget.hidden = false;
  }

  _showError(messages) {
    this.errorListTarget.innerHTML = messages.map((message) => `<li>${message}</li>`).join('');
    this.errorTarget.hidden = false;
    this.infoTarget.hidden = true;
    this.successTarget.hidden = true;
  }

  _showInfo(messages) {
    this.infoListTarget.innerHTML = messages.map((message) => `<li>${message}</li>`).join('');
    this.errorTarget.hidden = true;
    this.infoTarget.hidden = false;
    this.successTarget.hidden = true;
  }

  _showSuccess(messages) {
    this.successListTarget.innerHTML = messages.map((message) => `<li>${message}</li>`).join('');
    this.errorTarget.hidden = true;
    this.infoTarget.hidden = true;
    this.successTarget.hidden = false;
  }

  hideUploadProgress() {
    this.uploadProgressTarget.hidden = true;
  }

  get createPresentationEndpoint() {
    return this.element.dataset.createPresentationUrl;
  }

  get uploadInfoEndpoint() {
    return this.element.dataset.uploadInfoUrl;
  }

  get uploadCompleteEndpoint() {
    return this.element.dataset.uploadCompleteUrl;
  }

  get progressBarController() {
    return this.findControllerOnElement(this.progressBarTarget);
  }
}
