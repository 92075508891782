import ApplicationController from 'modules/application_controller';


export default class extends ApplicationController {
  static get targets() {
    return ['thirdParty', 'email'];
  }

  showEmail() {
    this.thirdPartyTarget.hidden = true;
    this.emailTarget.hidden = false;
  }

  hideEmail() {
    this.emailTarget.hidden = true;
    this.thirdPartyTarget.hidden = false;
  }
}
