import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  static get targets() {
    return ['updateRoleForm', 'updateRoleSelect', 'updateRoleSpinner', 'deleteButton', 'deleteSpinner'];
  }

  updateRoleChanged() {
    this.updateRoleFormTarget.requestSubmit();
  }

  submitUpdateRoleStart() {
    this.updateRoleSelectTarget.disabled = true;
    this.updateRoleSpinnerTarget.hidden = false;
  }

  submitUpdateRoleEnd() {
    this.updateRoleSpinnerTarget.hidden = true;
    this.updateRoleSelectTarget.disabled = false;
  }

  submitDeleteStart() {
    this.deleteButtonTarget.disabled = true;
    this.deleteSpinnerTarget.hidden = false;
  }

  submitDeleteEnd() {
    this.deleteSpinnerTarget.hidden = true;
    this.deleteButtonTarget.disabled = false;
  }
}
