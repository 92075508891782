import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  static get targets() {
    return ['thirdParty', 'email', 'signUp'];
  }

  showEmail(event) {
    if (event) event.preventDefault();

    if (this.hasThirdPartyTarget) this.thirdPartyTarget.hidden = true;
    if (this.hasEmailTarget) this.emailTarget.hidden = false;
    if (this.hasSignUpTarget) this.signUpTarget.hidden = true;
  }

  hideEmail(event) {
    if (event) event.preventDefault();

    if (this.hasThirdPartyTarget) this.thirdPartyTarget.hidden = false;
    if (this.hasEmailTarget) this.emailTarget.hidden = true;
    if (this.hasSignUpTarget) this.signUpTarget.hidden = true;
  }

  showSignUp(event) {
    if (event) event.preventDefault();

    if (this.hasThirdPartyTarget) this.thirdPartyTarget.hidden = true;
    if (this.hasEmailTarget) this.emailTarget.hidden = true;
    if (this.hasSignUpTarget) this.signUpTarget.hidden = false;
  }
}
