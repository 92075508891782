import ApplicationController from 'modules/application_controller';
import defer from 'plugins/utilities/defer';
import pushDataLayer from 'plugins/utilities/push_data_layer';

export default class extends ApplicationController {
  connect() {
    pushDataLayer({ sl_user_id: this.userId });

    defer(() => this.dispatch('initialize'));
  }

  get userId() {
    return this.element.dataset.userId;
  }
}
