import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  initialize() {
    this.props = {
      alreadyLoading: false,
    };
  }

  connect() {
    this.submitForm();
  }

  handleLoading(event) {
    if (event.detail.url !== this.submitUrl) {
      return;
    }

    this.alreadyLoading = true;
  }

  submitForm() {
    if (this.alreadyLoading) {
      return;
    }

    this.alreadyLoading = true;

    this.dispatch('loading', { target: document, detail: { url: this.submitUrl } });
    this.element.requestSubmit();
  }

  get submitUrl() {
    return this.element.action;
  }

  get alreadyLoading() {
    return this.props.alreadyLoading;
  }

  set alreadyLoading(value) {
    this.props.alreadyLoading = value;
  }
}
