import ApplicationController from 'modules/application_controller';

import Money from 'modules/money';

export default class extends ApplicationController {
  static get targets() {
    return ['private', 'unlisted', 'unpublished', 'price', 'thumbnail'];
  }

  setTitle(title) {
    this.thumbnailTarget.setAttribute('alt', title);
  }

  setPublished(published) {
    this.unpublishedTarget.hidden = published;
  }

  setPrivacy(privacy) {
    this.unlistedTarget.hidden = privacy !== '2';
    this.privateTarget.hidden = privacy !== '3';
  }

  setPrice(cents, currency) {
    const price = new Money(cents, currency);

    this.priceTarget.hidden = cents <= 0;
    this.priceTarget.textContent = price.format();
  }
}
