import isArray from 'plugins/utilities/is_array';

function mapToObject(map) {
  function convert(m) {
    return Array.from(m).reduce((acc, [key, value]) => {
      if (value instanceof Map) {
        acc[key] = convert(value);
      } else if (isArray(value)) {
        acc[key] = value.map((entry) => convert(entry));
      } else {
        acc[key] = value;
      }

      return acc;
    }, {});
  }

  return convert(map);
}

export default mapToObject;
