import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  static get targets() {
    return ['advancedButton'];
  }

  initialize() {
    this.props = {
      startTime: 0,
      startSlide: 0,
    };
  }

  updateAdvancedOptionsModalUrlParams() {
    const urlParams = [
      `start_time=${this.props.startTime}`,
      `start_slide=${this.props.startSlide}`,
    ].join('&');

    this.advancedButtonTarget.dataset.modalUrlParams = urlParams;
  }

  set startTime(time) {
    this.props.startTime = time;
    this.updateAdvancedOptionsModalUrlParams();
  }

  set startSlide(slide) {
    this.props.startSlide = slide;
    this.updateAdvancedOptionsModalUrlParams();
  }
}
