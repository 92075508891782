import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  static get targets() {
    return ['currentPasswordInput', 'passwordInput', 'passwordConfirmationInput'];
  }

  clearForm(event) {
    if (!event.detail.fetchResponse?.response?.ok) {
      return;
    }

    this.currentPasswordInputTarget.value = '';
    this.passwordInputTarget.value = '';
    this.passwordConfirmationInputTarget.value = '';
  }
}
