import checkDelayedResult from './check_delayed_result';

function checkDelayedResultWithPromise(urlOrDelayedResultId, checkInterval) {
  return new Promise((resolve, reject) => {
    checkDelayedResult(
      urlOrDelayedResultId,
      (result) => resolve(result),
      checkInterval,
      (errors) => reject(errors),
    );
  });
}

export default checkDelayedResultWithPromise;
