import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  static get targets() {
    return ['payoutMethodData', 'payoutMethodSelect', 'payoutMethod'];
  }

  updatePayoutMethodData() {
    const data = {};

    for (const el of this.payoutMethodTargets) {
      const method = el.dataset.dependValue;
      const paramEls = el.querySelectorAll('[data-payout-method-param]');

      data[method] = {};

      for (const paramEl of paramEls) {
        data[method][paramEl.dataset.payoutMethodParam] = paramEl.value;
      }
    }

    this.payoutMethodDataTarget.value = JSON.stringify(data);
  }
}
