import ApplicationController from 'modules/application_controller';
import VimeoPlayerApiLoader from 'vimeo-player-api-loader';

import './images/background,h_568.avif';
import './images/background,h_568.jpeg';
import './images/background,h_568.webp';
import './images/background,h_768.avif';
import './images/background,h_768.jpeg';
import './images/background,h_768.webp';
import './images/background,h_1024.avif';
import './images/background,h_1024.jpeg';
import './images/background,h_1024.webp';
import './images/background,h_1080.avif';
import './images/background,h_1080.jpeg';
import './images/background,h_1080.webp';

export default class extends ApplicationController {
  static get targets() {
    return ['video'];
  }

  initialize() {
    this.player = null;
    this.observer = new ResizeObserver(this.handleResize.bind(this));
  }

  connect() {
    if (this.isTurboPreview) return;

    this.loadVideo();
    this.observer.observe(this.element);
  }

  disconnect() {
    this.destroyVideo();
    this.observer.disconnect();
  }

  loadVideo() {
    if (this.player) return;

    VimeoPlayerApiLoader.load((Vimeo) => {
      this.player = new Vimeo.Player(this.videoTarget);

      this.player.on('error', this.handleVideoError.bind(this));
      this.player.on('play', this.handleVideoPlay.bind(this));
    });
  }

  async destroyVideo() {
    if (!this.player) return;

    await this.player.unload();

    this.player = null;
  }

  handleVideoError() {
    this.videoTarget.hidden = true;
  }

  handleVideoPlay() {
    this.videoTarget.hidden = false;
  }

  handleResize(entries) {
    for (const {
      contentRect: { width, height },
    } of entries) {
      this.updateSize(width, height);
    }
  }

  updateSize(width, height) {
    if (height === (width / 16) * 9) {
      return;
    }

    if (height < (width / 16) * 9) {
      height = (width / 16) * 9;
    } else {
      width = (height / 9) * 16;
    }

    Object.assign(this.videoTarget.style, {
      height: `${height}px`,
      width: `${width}px`,
    });
  }
}
