import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  static get targets() {
    return [
      'accountUrl',
      'editAccountUrl',
      'recordingRadiusModalLink',
      'recordingRadius',
      'recordingRadiusLat',
      'recordingRadiusLng',
    ];
  }

  connect() {
    this.updateUrl();
  }

  updateUrl() {
    if (`${window.location.origin}${window.location.pathname}` === this.editAccountUrl) {
      return;
    }

    this.element.setAttribute('action', this.accountUrl);
    window.history.replaceState(window.history.state, document.title, this.editAccountUrl);
  }

  updateRecordingRadius({ detail: { radius, lat, lng } }) {
    this.recordingRadiusTarget.value = radius;
    this.recordingRadiusLatTarget.value = lat;
    this.recordingRadiusLngTarget.value = lng;

    const url = new URL(this.recordingRadiusModalLinkTarget.dataset.modalUrl);
    url.searchParams.set('radius', radius);
    url.searchParams.set('lat', lat);
    url.searchParams.set('lng', lng);

    this.recordingRadiusModalLinkTarget.dataset.modalUrl = url;
  }

  get accountUrl() {
    return this.accountUrlTarget.value;
  }

  get editAccountUrl() {
    return this.editAccountUrlTarget.value;
  }
}
