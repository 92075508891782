import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  static get targets() {
    return ['textarea'];
  }

  submitEnd() {
    this.textareaTarget.value = '';
  }
}
