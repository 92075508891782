import ApplicationController from 'modules/application_controller';
import isBotUserAgent from 'plugins/utilities/is_bot_user_agent';

export default class extends ApplicationController {
  initialize() {
    this.props = {
      value: {},
      tracked: false,
    };
  }

  connect() {
    this.tryTrack();
  }

  tryTrack() {
    if (this.isTurboPreview) return;
    if (isBotUserAgent()) return;

    if (this.tracked || !this.isAtLeastHalfElementInViewport) {
      return;
    }

    const d = Math.floor(50 + Math.random() * 250);
    setTimeout(() => this.track(), d);
  }

  track() {
    if (this.tracked) return;
    if (isBotUserAgent()) return;

    this.tracked = true;

    const params = {
      impression_token: this.impressionToken,
      impression: { source_url: window.location.href, http_referer: document.referrer || '' },
    };

    fetch(this.impressionUrl, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'omit',
      headers: {
        'Content-Type': 'text/plain; charset=utf-8',
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify(params),
    }).catch((error) => {
      console.warn('IMPRESSION', 'sending impression failed', error);
    });
  }

  get tracked() {
    return this.props.tracked;
  }

  set tracked(value) {
    this.props.tracked = value;
  }

  get isAtLeastHalfElementInViewport() {
    const rect = this.boxElement.getBoundingClientRect();

    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom - this.boxElement.offsetHeight / 2 <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right - this.boxElement.offsetWidth / 2 <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  get impressionUrl() {
    return this.element.dataset.impressionUrl;
  }

  get impressionToken() {
    return this.element.dataset.impressionToken;
  }

  get isTurboPreview() {
    return document.documentElement.hasAttribute('data-turbo-preview');
  }

  get boxElement() {
    return this.element.parentNode;
  }
}
