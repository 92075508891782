import ApplicationController from 'modules/application_controller';
import pushDataLayer from 'plugins/utilities/push_data_layer';

export default class extends ApplicationController {
  track() {
    pushDataLayer({ event: this.action, follow_target: this.followId });
  }

  get action() {
    if (this.follow) {
      return 'sl_library_follow';
    }

    return 'sl_library_unfollow';
  }

  get follow() {
    return this.element.dataset.follow === 'true';
  }

  get followId() {
    return this.element.dataset.followId;
  }
}
