import PlayerBaseController from 'library/player_base_component/player_base_component';

export default class extends PlayerBaseController {
  load() {
    this.player.loadWhenVisible();
  }

  get playerOptions() {
    return {
      autoPlay: false,
      disableTracking: false,
      disableGoogleAnalytics: true,
      showThumbnail: false,
    };
  }
}
