import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  static get targets() {
    return ['form'];
  }

  initialize() {
    this.props = {
      done: false,
    };
  }

  followChanged(event) {
    if (this.done || event.srcElement.action.includes('unfollow')) {
      return;
    }

    this.done = true;
    this.formTarget.requestSubmit();
  }

  get done() {
    return this.props.done;
  }

  set done(value) {
    this.props.done = value;
  }
}
