import ApplicationController from 'modules/application_controller';
import base45 from 'base45';
import { Decoder } from 'cbor-x';
import jsQR from 'jsqr-es6';
import pako from 'pako';
import mapToObject from 'plugins/map/map_to_object';
import fire from 'plugins/utilities/fire';

export default class extends ApplicationController {
  static get targets() {
    return ['dataLineTemplate', 'inputData', 'inputInfo', 'jsonData', 'uploadedCertificate'];
  }

  connect() {
    this.parseUploadedCovidCertificates();
  }

  decodeQr(event) {
    const qrImage = event.target.files[0];

    if (!qrImage) {
      this.inputDataTarget.value = '';
      this.jsonDataTarget.value = '';
      this.inputInfoTarget.innerHTML = '';

      return;
    }

    const reader = new FileReader();
    const img = document.createElement('img');
    const canvas = document.createElement('canvas');
    // const canvas = document.getElementById('canvas');

    reader.onload = (e) => {
      img.src = e.target.result;
    };

    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);

      const imageData = ctx.getImageData(0, 0, img.width, img.height);
      const decodedQR = jsQR(imageData.data, imageData.width, imageData.height);

      this.inputDataTarget.value = decodedQR.data;

      const decodedBody = decodedQR.data.substr(4);
      const decodedData = base45.decode(decodedBody);
      const inflatedData = pako.inflate(decodedData);
      const cborResults = new Decoder().decode(inflatedData);

      const cborData = cborResults.value[2];

      const data = new Decoder().decode(cborData);
      const jsonData = JSON.stringify(mapToObject(data.get(-260).get(1)), null, 2);

      this.jsonDataTarget.value = jsonData;
      fire(this.jsonDataTarget, 'change');

      this.parseCovidCertificateInfoFromJSON(jsonData, this.inputInfoTarget);
    };

    reader.readAsDataURL(qrImage);
  }

  parseUploadedCovidCertificates() {
    for (const element of this.uploadedCertificateTargets) {
      this.parseCovidCertificateInfoFromJSON(element.dataset.certificateJson, element);
    }
  }

  parseCovidCertificateInfoFromJSON(jsonString, element) {
    const json = JSON.parse(jsonString);

    const info = [];

    if (json.v) {
      info.push(['Certificate type', 'vaccination']);
    } else if (json.r) {
      info.push(['Certificate type', 'recovery']);
    } else if (json.t) {
      info.push(['Certificate type', 'test']);
    }

    info.push(['Date of birth', json.dob]);
    info.push(['First name', json.nam.gn]);
    info.push(['Last name', json.nam.fn]);

    if (json.v) {
      const v = json.v[0];

      info.push(['Vaccine', v.mp]);
      info.push(['Current dose number', v.dn]);
      info.push(['Total dose number', v.sd]);
      info.push(['Date of vaccination', v.dt]);
      info.push(['Place of vaccination', v.co]);
      info.push(['Certificate issuer', v.is]);
    } else if (json.r) {
      const r = json.r[0];

      info.push(['Date of positive test', r.fr]);
      info.push(['Place of positive test', r.co]);
      info.push(['Certificate issuer', r.is]);
      info.push(['Certificate valid from', r.df]);
      info.push(['Certificate valid until', r.cu]);
    } else if (json.t) {
      const t = json.t[0];

      info.push(['Test type', t.tt]);
      info.push(['Test name', t.tn]);
      info.push(['Test device ID', t.ma]);
      info.push(['Date and time of sample collection', t.sc]);
      info.push(['Test result', t.tr]);
      info.push(['Testing centre', t.tc]);
      info.push(['Testing country', t.co]);
      info.push(['Certificate issuer', t.is]);
    }

    element.innerHTML = info
      .map((pair) => this.dataLineTemplateTarget.innerHTML.replace('{content}', `${pair[0]}: ${pair[1]}`))
      .join('');
    element.hidden = false;
  }
}
