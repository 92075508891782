import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  static get targets() {
    return ['server', 'quality720p', 'quality720pLq', 'slidesQuality720p', 'slidesQuality720pLq'];
  }

  connect() {
    this.updateOutgoingStreamQuality();
  }

  updateOutgoingStreamQuality() {
    const server = this.serverTarget.selectedOptions[0];
    const quality = server.dataset.outgoingStreamQuality;

    if (!this.hasQuality720pTarget || !this.hasQuality720pLqTarget) {
      return;
    }

    if (quality === '1080p') {
      this.quality720pTarget.checked = false;
      this.quality720pLqTarget.checked = false;

      if (this.hasSlidesQuality720pTarget) {
        this.slidesQuality720pTarget.checked = true;
      }

      if (this.hasSlidesQuality720pLqTarget) {
        this.slidesQuality720pLqTarget.checked = false;
      }
    } else if (quality === '720p') {
      this.quality720pTarget.checked = true;
      this.quality720pLqTarget.checked = false;

      if (this.hasSlidesQuality720pTarget) {
        this.slidesQuality720pTarget.checked = true;
      }

      if (this.hasSlidesQuality720pLqTarget) {
        this.slidesQuality720pLqTarget.checked = false;
      }
    } else if (quality === '720p-lq') {
      this.quality720pTarget.checked = false;
      this.quality720pLqTarget.checked = true;

      if (this.hasSlidesQuality720pTarget) {
        this.slidesQuality720pTarget.checked = false;
      }

      if (this.hasSlidesQuality720pLqTarget) {
        this.slidesQuality720pLqTarget.checked = true;
      }
    }
  }
}
