import ApplicationController from 'modules/application_controller';
import addListener from 'plugins/utilities/add_listener';
import fire from 'plugins/utilities/fire';
import removeListener from 'plugins/utilities/remove_listener';

export default class extends ApplicationController {
  connect() {
    this.messageListenerId = addListener(window, 'message onmessage', this.handleMessage.bind(this));
  }

  disconnect() {
    removeListener(window, { id: this.messageListenerId });
  }

  sendMessage(message) {
    message.slideslive = true;
    message.targetId = this.embedContainerId;

    if (window.ActiveXObject) {
      message = JSON.stringify(message);
    }

    window.parent.postMessage(message, this.embedOrigin);
  }

  handleMessage(event) {
    if (event.origin !== this.embedOrigin && event.origin !== window.origin) {
      return true;
    }

    let { data } = event;

    try {
      if (typeof data === 'string') {
        data = JSON.parse(data.replace('/*framebus*/', ''));
      }
    } catch (e) {
      return true;
    }

    if (typeof data !== 'object' || !data.slideslive) {
      return true;
    }

    fire(window, 'embed:message', { detail: { message: data } });
    return true;
  }

  sendSetSize({ width = '100%', height }) {
    if (!this.embedOrigin || !this.embedContainerId) {
      return;
    }

    this.sendMessage({
      method: 'setSize',
      width,
      height,
    });
  }

  sendUpdateSizeRequest() {
    if (!this.embedOrigin || !this.embedContainerId) {
      return;
    }

    this.sendMessage({ method: 'updateSizeRequest' });
  }

  get embedOrigin() {
    return this.element.dataset.embedOrigin;
  }

  get embedContainerId() {
    return this.element.dataset.embedContainerId;
  }
}
