import ApplicationController from 'modules/application_controller';
import remove from 'plugins/element/remove';

export default class extends ApplicationController {
  static get targets() {
    return ['player'];
  }

  loadPlayer(event) {
    remove(event.target);

    this.playerTarget.hidden = false;
    this.playerController.load();
  }

  get playerController() {
    return this.findControllerByName('library--presentation-edit--player', {
      useQuerySelector: false,
      element: this.playerTarget,
    });
  }
}
