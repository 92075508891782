import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  static get targets() {
    return ['availableOnDemandRadio', 'terminateStreamRadio', 'processStreamRecording', 'processStreamRecordingField'];
  }

  triggerProcessStreamRecordingFields() {
    if (this.availableOnDemandRadioTarget.checked) {
      this.processStreamRecordingTarget.checked = false;
    }

    const show = this.shouldShowProcessStreamRecordingFields;

    for (const field of this.processStreamRecordingFieldTargets) {
      field.hidden = !show;
    }
  }

  get shouldShowProcessStreamRecordingFields() {
    if (this.availableOnDemandRadioTarget.checked) {
      return true;
    }

    if (this.terminateStreamRadioTarget.checked && this.processStreamRecordingTarget.checked) {
      return true;
    }

    return false;
  }
}
