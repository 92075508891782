import colors from '@/fuse/colors';
import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  static get targets() {
    return ['firstColor', 'secondColor'];
  }

  resetFirstColor() {
    this.resetColor(this.firstColorTarget, colors.gradient.from);
  }

  resetSecondColor() {
    this.resetColor(this.secondColorTarget, colors.gradient.to);
  }

  resetColor(input, color) {
    input.value = color;
  }
}
