import ApplicationController from 'modules/application_controller';


export default class extends ApplicationController {
  initialize() {}

  connect() {}

  disconnect() {}
}
