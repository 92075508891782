import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  static get targets() {
    return ['cloudflareJson', 'submitButton'];
  }

  static get outlets() {
    return ['fuse--button'];
  }

  connect() {
    this.loading = true;

    this._fetchCloudflareJson();
  }

  fuseButtonOutletConnected(outlet) {
    this._syncFuseButtonOutletLoading(outlet);
  }

  _fetchCloudflareJson() {
    if (!this.hasCloudflareJsonTarget) {
      this.loading = false;

      return;
    }

    fetch('https://checkme.slideslive.com/api/v1/ping', {
      credentials: 'omit',
      referrerPolicy: 'no-referrer',
    })
      .then((response) => response.json())
      .then((json) => {
        this.cloudflareJsonTarget.value = JSON.stringify(json);

        this.loading = false;
      })
      .catch((error) => {
        console.warn(error);

        this.loading = false;
      });
  }

  get loading() {
    return this._loading;
  }

  set loading(value) {
    this._loading = value;
    this._syncFuseButtonOutletLoading();
  }

  _syncFuseButtonOutletLoading(outlet) {
    if (!outlet) {
      if (!this.hasFuseButtonOutlet) return;

      outlet = this.fuseButtonOutlet;
    }

    if (this.loading) {
      outlet.loading();
    } else {
      outlet.done();
    }
  }
}
