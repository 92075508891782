import ApplicationController from 'modules/application_controller';
import remove from 'plugins/element/remove';

export default class extends ApplicationController {
  loadAllPresentations({ target }) {
    remove(target);
    this.dispatch('loadAll', { target: window, detail: { feed: 'presentations' } });
  }

  loadAllFolders({ target }) {
    remove(target);
    this.dispatch('loadAll', { target: window, detail: { feed: 'folders' } });
  }
}
