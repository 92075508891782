import ApplicationController from 'modules/application_controller';
import * as PermanentPlayer from 'modules/permanent_player';
import Player from 'modules/player/player';

export default class extends ApplicationController {
  initialize() {
    this.props = {
      player: null,
      benUrlInitialized: false,
      isPermanent: false,
    };
  }

  connect() {
    this.initBenUrl();
    this.initPlayer();
  }

  disconnect() {
    if (this.player && !this.isPermanent) {
      this.destroy();
      this.player = null;
    }
  }

  initBenUrl() {
    if (this.benUrlInitialized) {
      return;
    }

    this.benUrlInitialized = true;

    if (gon.env !== 'development') {
      return;
    }

    const host = window.location.host.split('.');

    while (host.length > 2) {
      host.shift();
    }

    const meta = document.createElement('meta');
    meta.name = 'ben-base-url';
    meta.content = `${window.location.protocol}//${host.join('.')}`;

    document.head.insertAdjacentElement('beforeend', meta);
  }

  initPlayer() {
    if (this.isTurboPreview || this.player) {
      if (this.player) {
        this.player.updateSize();
      }

      return false;
    }

    this.player = new Player(this.element.id, this.playerOptions);
    this.player.on('play', () => {
      if (this.player.firstPlay && PermanentPlayer.exist()) {
        PermanentPlayer.close();
      }
    });

    return true;
  }

  play() {
    this.player.play();
  }

  pause() {
    this.player.pause();
  }

  seekTo(seconds) {
    this.player.seekTo(seconds * 1000);
  }

  seekSlide(slide) {
    this.player.seekToSlide(slide);
  }

  updateSize() {
    this.player.updateSize();
  }

  destroy() {
    this.player.destroy();
  }

  get playerOptions() {
    return {};
  }

  get player() {
    return this.props.player;
  }

  set player(value) {
    this.props.player = value;
  }

  get benUrlInitialized() {
    return this.props.benUrlInitialized;
  }

  set benUrlInitialized(value) {
    this.props.benUrlInitialized = value;
  }

  get isPermanent() {
    return this.props.isPermanent;
  }

  set isPermanent(value) {
    this.props.isPermanent = value;
  }
}
