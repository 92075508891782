import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  static get targets() {
    return ['link', 'embed', 'startTime', 'startSlide', 'startTimeCheck', 'startSlideCheck'];
  }

  initialize() {
    this.props = {
      originalShareLink: null,
    };

    this.originalShareLink = this.linkTarget.value;
  }

  connect() {
    this.startTimeTarget.value = this.startTime;

    if (this.hasStartSlideTarget) {
      this.startSlideTarget.value = this.startSlide + 1;
    }

    this.updateEmbed();
  }

  updateStartTime() {
    if (!this.startTimeCheckTarget.checked) {
      this.startTime = null;
      return;
    }

    const timeParts = this.startTimeTarget.value.split(':');
    let time = 0;

    if (timeParts.length === 1) {
      time = parseInt(timeParts[0].trim(), 10);
    } else {
      do {
        const value = parseInt(timeParts.shift(), 10);
        const exponent = timeParts.length;
        time += value * 60 ** exponent;
      } while (timeParts.length);
    }

    if (Number.isNaN(time) || time < 0) {
      this.startTime = null;
      return;
    }

    this.startTime = time;
  }

  updateStartSlide() {
    if (!this.hasStartSlideTarget) return;

    const slide = parseInt(this.startSlideTarget.value, 10) - 1;

    if (Number.isNaN(slide) || slide < 0) {
      this.startSlide = null;
      return;
    }

    this.startSlide = slide;
  }

  toggleStartTime() {
    const checked = this.startTimeCheckTarget.checked;

    this.startTimeTarget.disabled = !checked;

    if (checked && this.hasStartSlideTarget) {
      this.startSlideCheckTarget.checked = false;
      this.startSlideCheckTarget.dispatchEvent(new Event('change'));
    }
  }

  toggleStartSlide() {
    const checked = this.startSlideCheckTarget.checked;

    this.startSlideTarget.disabled = !checked;

    if (checked) {
      this.startTimeCheckTarget.checked = false;
      this.startTimeCheckTarget.dispatchEvent(new Event('change'));
    }
  }

  update() {
    this.updateLink();
    this.updateEmbed();
  }

  updateLink() {
    let link = this.originalShareLink;

    if (this.hasStartTime) {
      link += `?time=${this.startTime}`;
    } else if (this.hasStartSlide) {
      link += `?slide=${this.startSlide}`;
    }

    this.linkTarget.value = link;
  }

  updateEmbed() {
    const options = { ...this.defaultEmbedOptions };

    if (this.hasStartTime) {
      options.startTime = this.startTime;
    } else if (this.hasStartSlide) {
      options.startSlide = this.startSlide;
    }

    let html = `<div id="${this.embedId}"></div>\n`;
    html += `<script src="${this.embedSrc}"></script>\n`;
    html += '<script>\n';
    html += `  embed = new SlidesLiveEmbed("${this.embedId}", {\n`;

    Object.keys(options).forEach((key) => {
      html += `    ${key}: ${JSON.stringify(options[key])},\n`;
    });

    html += '  });\n';
    html += '</script>';

    this.embedTarget.value = html;
  }

  get hasStartTime() {
    return this.startTimeCheckTarget.checked;
  }

  get startTime() {
    return parseInt(this.element.dataset.startTime, 10) || 0;
  }

  set startTime(value) {
    this.element.dataset.startTime = value;
    this.update();
  }

  get hasStartSlide() {
    return this.hasStartSlideTarget && this.startSlideCheckTarget.checked;
  }

  get startSlide() {
    return parseInt(this.element.dataset.startSlide, 10) || 0;
  }

  set startSlide(value) {
    this.element.dataset.startSlide = value;
    this.update();
  }

  get originalShareLink() {
    return this.props.originalShareLink;
  }

  set originalShareLink(value) {
    this.props.originalShareLink = value;
  }

  get presentationId() {
    return this.element.dataset.presentationId;
  }

  get defaultEmbedOptions() {
    return {
      presentationId: `${this.presentationId}`,
      autoPlay: false,
      verticalEnabled: true,
    };
  }

  get embedSrc() {
    return 'https://slideslive.com/embed_presentation.js';
  }

  get embedId() {
    return `presentation-embed-${this.presentationId}`;
  }
}
