import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  static get targets() {
    return ['reloadSpinner'];
  }

  initialize() {
    this.props = {
      reloadTimeout: null,
      panelTarget: null,
    };
  }

  connect() {
    this.initReloadTimeout();
  }

  disconnect() {
    if (this.reloadTimeout) {
      clearInterval(this.reloadTimeout);
    }
  }

  initReloadTimeout() {
    this.reloadTimeout = setTimeout(this.reload.bind(this), 10000);
  }

  reload() {
    if (this.reloadTimeout) {
      clearTimeout(this.reloadTimeout);
      this.reloadTimeout = null;
    }

    if (!this.isVisible) {
      this.initReloadTimeout();
      return;
    }

    this.reloadSpinnerTarget.hidden = false;
    this.frameTarget.reload();
  }

  get frameTarget() {
    return this.element.parentNode;
  }

  get reloadTimeout() {
    return this.props.reloadTimeout;
  }

  set reloadTimeout(value) {
    this.props.reloadTimeout = value;
  }

  get isVisible() {
    return !this.panelTarget.hidden;
  }

  get panelTarget() {
    if (!this.props.panelTarget) {
      this.props.panelTarget = this.element.closest('[role="tabpanel"]');
    }

    return this.props.panelTarget;
  }
}
