import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  static get targets() {
    return ['radius', 'search', 'map'];
  }

  connect() {
    this.initializeMap();
  }

  initializeMap() {
    if (!window.isMapApiInitialized(this.initializeMap.bind(this))) {
      return;
    }

    const defaultLatLng = {
      lat: this.lat,
      lng: this.lng,
    };

    const mapOptions = {
      mapTypeId: 'roadmap',
      center: defaultLatLng,
      zoom: 5,
      mapTypeControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
    };

    this.map = new google.maps.Map(this.mapTarget, mapOptions);

    const markerOptions = {
      position: defaultLatLng,
      map: this.map,
      draggable: !this.readonly,
    };

    this.marker = new google.maps.Marker(markerOptions);

    const circleOptions = {
      strokeColor: '#FF0000',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#FF0000',
      fillOpacity: 0.35,
      map: this.map,
      editable: !this.readonly,
      radius: this.radius,
    };

    this.circle = new google.maps.Circle(circleOptions);
    this.circle.bindTo('center', this.marker, 'position');
    this.circle.bindTo('map', this.marker, 'map');

    if (!this.readonly) {
      this.marker.addListener('position_changed', () => this.setPositionFromMap(this.marker.getPosition()));

      this.circle.addListener('radius_changed', () => {
        const radius = Math.round(this.circle.getRadius());

        this.setRadiusFromMap(radius);
      });

      this.searchBox = new google.maps.places.SearchBox(this.searchTarget);
      this.searchBox.addListener('places_changed', () => {
        const places = this.searchBox.getPlaces();
        if (places.length > 0) {
          const place = places[0];
          this.marker.setPosition(place.geometry.location);
          this.setPositionFromMap(place.geometry.location);
          this.map.setCenter(place.geometry.location);
        }
      });

      this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(this.searchTarget);
    }
  }

  setPositionFromMap(position) {
    this.lat = position.lat();
    this.lng = position.lng();
  }

  setRadiusFromMap(radius) {
    this.radius = radius;
  }

  setRadiusFromInput() {
    this.circle.setRadius(this.radius);
  }

  fireRecordingRadiusChanged(event) {
    event.preventDefault();

    this.dispatchOnWindow('radiusChanged', { detail: { lat: this.lat, lng: this.lng, radius: this.radius } });
  }

  get readonly() {
    return this.element.dataset.readonly === 'true';
  }

  get lat() {
    return parseFloat(this.element.dataset.lat);
  }

  set lat(value) {
    this.element.dataset.lat = value;
  }

  set lng(value) {
    this.element.dataset.lng = value;
  }

  get lng() {
    return parseFloat(this.element.dataset.lng);
  }

  get radius() {
    return parseInt(this.radiusTarget.value, 10) * 1000;
  }

  set radius(value) {
    this.radiusTarget.value = Math.round(value / 1000);
  }
}
